.more {
    background-color: #fff;
    color: black;
    padding: 1.25em 2em;
    font-size: 1em;
    border: 1px solid #c9c9c9;
    transition: .3s;
}

.more:hover {
    cursor: pointer;
    background-color: #ececec;
    transition: .3s;
}